import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { ApiService } from './../../service/api.service';
@Component({
    selector: 'landing',
    templateUrl: 'landing.component.html',
})
export class LandingComponent implements OnInit {

    constructor(
        private readonly router: Router,
        private readonly api: ApiService) { }

    ngOnInit() {
        console.log('landing page');
    }
}
