import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './../../../service/api.service';

@Component({
    selector: 'profit-loss',
    templateUrl: 'profit-loss.component.html',
})
export class ProfitLossComponent implements OnInit {

    constructor(
        private readonly router: Router,
        public readonly api: ApiService
    ) { }

    ngOnInit() {
        console.log('profit loss');
    }
}
