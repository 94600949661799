import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './../../../service/api.service';

import { Invoice } from './../../../model/transaction/invoice';

@Component({
    selector: 'invoice',
    templateUrl: 'invoice.component.html',
})
export class InvoiceComponent implements OnInit {

    constructor(
        private readonly router: Router,
        public readonly api: ApiService
    ) { }

    public invoices = [
        {
            id: 1,
            invoiceFrom: {
                companyName: 'Tokkea Co., Ltd.'
            },
            invoiceDate: '21 Aug 2020',
            invoiceTotal: 8000,
            currencyType: {
                symbol: 'USD'
            }
        },
        {
            id: 2,
            invoiceFrom: {
                companyName: 'IRS'
            },
            invoiceDate: '21 Aug 2020',
            invoiceTotal: 1500,
            currencyType: {
                symbol: 'USD'
            }
        },
        {
            id: 5,
            invoiceFrom: {
                companyName: 'NYC Coworking'
            },
            invoiceDate: '21 Aug 2020',
            invoiceTotal: 7600,
            currencyType: {
                symbol: 'USD'
            }
        }
    ];

    ngOnInit() {
        console.log('invoices');
    }
    viewInvoice(invoiceId: number): void {
        console.log(`view invoice: ${invoiceId}`);
        this.router.navigate(['/ar', invoiceId]);
        /*
        .then(() => {
          window.location.reload();
        });
        */
    }
}
