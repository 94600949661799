import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './views/landing/landing.component'

import { BalanceSheetComponent } from './views/accounting/balance-sheet/balance-sheet.component'
import { BalanceSheetDetailComponent } from './views/accounting/balance-sheet/balance-sheet-detail.component'

import { ForecastComponent } from './views/accounting/forecast/forecast.component'
import { ForecastDetailComponent } from './views/accounting/forecast/forecast-detail.component'

import { AccountComponent } from './views/accounting/general-ledger/account.component'
import { AccountDetailComponent } from './views/accounting/general-ledger/account-detail.component'

import { ProfitLossComponent } from './views/accounting/profit-loss/profit-loss.component'
import { ProfitLossDetailComponent } from './views/accounting/profit-loss/profit-loss-detail.component'

import { BillComponent } from './views/business/payable/bill.component'
import { BillDetailComponent } from './views/business/payable/bill-detail.component'
import { BillCreateComponent } from './views/business/payable/bill-create.component'

import { InvoiceComponent } from './views/business/receivable/invoice.component'
import { InvoiceDetailComponent } from './views/business/receivable/invoice-detail.component'
import { InvoiceCreateComponent } from './views/business/receivable/invoice-create.component'

import { CustomerComponent } from './views/contact/customer/customer.component'
import { CustomerDetailComponent } from './views/contact/customer/customer-detail.component'
import { CustomerAddComponent } from './views/contact/customer/customer-add.component'

import { SupplierComponent } from './views/contact/supplier/supplier.component'
import { SupplierDetailComponent } from './views/contact/supplier/supplier-detail.component'
import { SupplierAddComponent } from './views/contact/supplier/supplier-add.component'

// import { MessageComponent } from './views/message/message.component'

const routes: Routes = [
  {
      path: 'ar',
      children: [
          {
              path: '',
              component: InvoiceComponent
          },
          {
            path: 'create',
              component: InvoiceCreateComponent
          },
          {
            path: ':invoice_id',
              component: InvoiceDetailComponent
          }
      ]
  },
  {
      path: 'ap',
      children: [
          {
              path: '',
              component: BillComponent
          },
          {
            path: 'create',
              component: BillCreateComponent
          },
          {
            path: ':bill_id',
              component: BillDetailComponent
          }
      ]
  },
  {
      path: 'ledger',
      children: [
          {
              path: '',
              component: AccountComponent
          },
          {
            path: ':ledger_id',
              component: AccountDetailComponent
          }
      ]
  },
  {
      path: 'account',
      children: [
          {
              path: '',
              component: BalanceSheetComponent
          },
          {
            path: ':account_id',
              component: BalanceSheetDetailComponent
          }
      ]
  },
  {
      path: 'pl',
      children: [
          {
              path: '',
              component: ProfitLossComponent
          },
          {
            path: ':pl_id',
              component: ProfitLossDetailComponent
          }
      ]
  },
  {
      path: 'forecast',
      children: [
          {
              path: '',
              component: ForecastComponent
          },
          {
            path: ':forecast_id',
              component: ForecastDetailComponent
          }
      ]
  },
  {
      path: 'customer',
      children: [
          {
              path: '',
              component: CustomerComponent
          },
          {
            path: 'add',
              component: CustomerAddComponent
          },
          {
            path: ':customer_id',
              component: CustomerDetailComponent
          }
      ]
  },
  {
      path: 'supplier',
      children: [
          {
              path: '',
              component: SupplierComponent
          },
          {
            path: 'add',
              component: SupplierAddComponent
          },
          {
            path: ':supplier_id',
              component: SupplierDetailComponent
          }
      ]
  },
  { 
    path: '**', 
    component: LandingComponent 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
