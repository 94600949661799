<main>
    <div class="uk-container">
        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
            <h1 class="uk-heading-small uk-text-center">Profit &amp; Loss</h1>

            <div class="uk-card uk-card-default uk-padding-small uk-text-center uk-overflow-auto">
                <table class="uk-table uk-table-divider uk-table-small table-report table-balance">
                <thead>
                    <tr class="report-header">
                        <th colspan="6">
                            Lalamove Automotive (Thailand) Limited<br />
                            For the month ending 31 December 2020
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="report-subheader">
                        <td></td>
                        <td>Dec-20</td>
                        <td>Nov-20</td>
                        <td>Oct-20</td>
                        <td>Sep-20</td>
                        <td>YTD</td>
                    </tr>

                    <tr class="report-section">
                        <td colspan="6" class="report-header">Income</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Revenue-Corporate Sales</td>
                        <td>30,400</td>
                        <td>204,250</td>
                        <td>102,900</td>
                        <td>419,050</td>
                        <td>884,000</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Revenue-Project Work</td>
                        <td>-</td>
                        <td>1,745,700</td>
                        <td>223,300</td>
                        <td>-</td>
                        <td>1,949,000</td>
                    </tr>
                    <tr class="report-section-total">
                        <td class="report-header">Total Income</td>
                        <td>30,400</td>
                        <td>1,949,950</td>
                        <td>326,200</td>
                        <td>419,050</td>
                        <td>2,853,000</td>
                    </tr>
                    
                    <tr class="report-section">
                        <td colspan="6" class="report-header">Less Cost of Sales</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Direct costs-Others</td>
                        <td>-</td>
                        <td>-</td>
                        <td>16,500</td>
                        <td>-</td>
                        <td>16,500</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Direct costs-Vehicle Rental</td>
                        <td>-</td>
                        <td>-</td>
                        <td>307,200</td>
                        <td>549,700</td>
                        <td>968,250</td>
                    </tr>
                    <tr class="report-section-total">
                        <td class="report-header">Total Cost of Sales</td>
                        <td>-</td>
                        <td>-</td>
                        <td>323,700</td>
                        <td>549,700</td>
                        <td>985,250</td>
                    </tr>

                    <tr class="report-group-total">
                        <td class="report-header">Gross Profit</td>
                        <td>30,400</td>
                        <td>1,949,950</td>
                        <td>2,500</td>
                        <td>(130,650)</td>
                        <td>1,867,750</td>
                    </tr>

                    
                    <tr class="report-spacer"><td></td></tr>
                    <tr class="report-spacer"><td></td></tr>

                    <tr class="report-section">
                        <td colspan="6" class="report-header">Plus Other Income</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Other revenue-Bank interest income</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>13</td>
                    </tr>
                    <tr class="report-section-total">
                        <td class="report-header">Total Other Income</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>13</td>
                    </tr>


                    <tr class="report-section">
                        <td colspan="6" class="report-header">Less Operating Expenses</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">IT exp-Server fees</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>5,000</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Legal and professional fee-Consultancy Fee</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1,523</td>
                        <td>52,631</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Legal and professional fee-Legal Fee</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>7,601</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Marketing exp-Offline: Stickers (Others)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>100,000</td>
                        <td>-</td>
                        <td>100,000</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">PPE-Computer exp</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>27,713</td>
                        <td>129,363</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">PPE-Fixture, furniture and office equipment</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>3,531</td>
                        <td>19,581</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Rental exp</td>
                        <td>-</td>
                        <td>-</td>
                        <td>39,162</td>
                        <td>47,722</td>
                        <td>165,208</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Staff costs-Full-time salaries</td>
                        <td>-</td>
                        <td>-</td>
                        <td>458,042</td>
                        <td>346,500</td>
                        <td>2,538,434</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Staff costs-Pension (employer)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1,800</td>
                        <td>1,500</td>
                        <td>18,323</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Staff costs-Recruitment (advertising)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>193,884</td>
                        <td>-</td>
                        <td>193,884</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Staff costs-Welfare (others)</td>
                        <td>-</td>
                        <td>-</td>
                        <td>4,589</td>
                        <td>-</td>
                        <td>4,589</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Tax penalty</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1,000</td>
                        <td>-</td>
                        <td>1,000</td>
                    </tr>
                    <tr class="report-section-total">
                        <td class="report-header">Total Operating Expenses</td>
                        <td>-</td>
                        <td>-</td>
                        <td>798,477</td>
                        <td>428,489</td>
                        <td>3,235,618</td>
                    </tr>

                    <tr class="report-group-total">
                        <td class="report-header">Net Profit</td>
                        <td>30,400</td>
                        <td>1,949,950</td>
                        <td>(795,977)</td>
                        <td>(599,139)</td>
                        <td>(1,367,852)</td>
                    </tr>

                 </tbody>
                </table>
            </div>
        </div>
    </div>
</main>