<main>
    <div class="uk-container">
        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
            <h1 class="uk-heading-small uk-text-center">Forecast</h1>

            <div class="uk-card uk-card-default uk-padding-small uk-text-center uk-overflow-auto">
                <table class="uk-table uk-table-divider uk-table-small table-forecast">
                <thead>
                    <tr class="forecast-header">
                        <th>{{forecastSheet.companyName}}</th>
                        <th *ngFor="let timespan of forecastSheet.timespans; first as isFirst" class="forecast-timespan" 
                            [class.forecast-timespan-first]="isFirst">
                            {{timespan}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="forecast-subheader">
                        <td class="forecast-units">Units in {{forecastSheet.currencyType}}</td>
                        <td *ngFor="let timespan of forecastSheet.timespans; first as isFirst">
                            <span *ngIf="isFirst">Actual</span>
                            <span *ngIf="!isFirst">Forecast</span>
                        </td>
                    </tr>
                    <ng-template ngFor let-forecast [ngForOf]="forecastSheet.forecasts">
                        <tr class="forecast-group-header">
                            <td>{{forecast.group}}</td>
                            <td [attr.colspan]="forecastSheet.timespans.length"></td>
                        </tr>
                        <ng-template ngFor let-item [ngForOf]="forecast.lineItems">
                            <tr>
                                <td class="forecast-item">{{item.itemName}}</td>
                                <ng-template ngFor let-projection [ngForOf]="item.projections">
                                    <td class="forecast-projection">{{projection | number}}</td>
                                </ng-template>
                            </tr>
                        </ng-template>
                        <tr class="forecast-totals">
                            <td class="forecast-total-header">Total {{forecast.group}}</td>
                            <ng-template ngFor let-total [ngForOf]="forecast.totals">
                                <td class="forecast-total">{{total | number}}</td>
                            </ng-template>
                        </tr>
                    </ng-template>
                </tbody>
                </table>
            </div>
        </div>
    </div>
</main>

<footer>
    <nav class="uk-navbar uk-navbar-container">
        <div class="uk-navbar-center uk-margin-bottom uk-margin-top">
            <a (click)="api.navigateTo('/forecast',0)" class="uk-button uk-button-primary">Edit Forecast</a>
        </div>
    </nav>
</footer>