<main>
    <div class="uk-container">
        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
            <h1 class="uk-heading-small uk-text-center">Create Invoice</h1>

            <form>
                <div class="uk-card uk-card-default uk-padding-small uk-text-center">
                    <ul class="uk-subnav uk-subnav-pill uk-flex uk-flex-center" uk-switcher>
                        <li><a href="#">Enter your details</a></li>
                        <li><a href="#">Enter invoice details</a></li>
                        <li><a href="#">Enter client details</a></li>
                        <li><a href="#">Done!</a></li>
                    </ul>
                    <ul class="uk-switcher uk-margin">
                        <li>
                            <fieldset class="uk-fieldset">
                                <legend class="uk-legend">Enter your details</legend>
                        
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="CompanyName" placeholder="Your Company name">
                                </div>                        
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="ContactPerson" placeholder="Contact Person">
                                </div>                                
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="ContactEmail" placeholder="Contact Email">
                                </div>                
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="ContactPhoneNumber" placeholder="Contact Phone number">
                                </div>
                                
                                
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="AddressLBenine1" placeholder="Address Line 1">
                                </div>                
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="AddressLine2" placeholder="Address Line 2">
                                </div>                
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="RegionCode" placeholder="Zip or Region Code">
                                </div>              
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="Country" placeholder="Country">
                                </div>
                                
        
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="BeneficiaryName" placeholder="Beneficiary name">
                                </div>
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="BankName" placeholder="Bank Name">
                                </div>
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="SwiftCode" placeholder="SWIFT">
                                </div>
                                <div class="uk-margin">
                                    <input class="uk-input" type="text" name="AccountNumber" placeholder="Bank Account Number">
                                </div>
                            </fieldset>
                            <a href="#" uk-switcher-item="next" class="uk-button uk-button-primary">Next</a>
                        </li>
                        <li>
                            <fieldset class="uk-fieldset">
                                <legend class="uk-legend">Enter invoice details</legend>
                            </fieldset>
                            <a href="#" uk-switcher-item="next" class="uk-button uk-button-primary">Next</a>
                        </li>
                        <li>
                            <fieldset class="uk-fieldset">
                                <legend class="uk-legend">Enter client details</legend>
                            </fieldset>
                            <a href="#" uk-switcher-item="next" class="uk-button uk-button-primary">Next</a>
                        </li>
                        <li>
                            <fieldset class="uk-fieldset">
                                <legend class="uk-legend">Done!</legend>
                            </fieldset>
                            <a (click)="api.navigateTo('/ar',0)" class="uk-button uk-button-primary">Save</a>
                        </li>
                    </ul>

                </div>
            </form>

        </div>
    </div>
</main>

<footer>
    <nav class="uk-navbar uk-navbar-container">
        <div class="uk-navbar-center uk-margin-bottom uk-margin-top">
            <a (click)="api.navigateTo('/ar',0)" class="uk-button uk-button-primary">Back to Invoices</a>
        </div>
    </nav>
</footer>