import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { ApiService } from './../../../service/api.service';

@Component({
    selector: 'account',
    templateUrl: 'account.component.html',
})
export class AccountComponent implements OnInit {

    constructor(
        private readonly router: Router,
        public readonly api: ApiService
    ) { }

    public ledgerItems = [
        {
            id: 1,
            liFrom: {
                companyName: 'Tokkea Co., Ltd.'
            },
            liDate: '21 Aug 2020',
            liTotal: 8000,
            currencyType: {
                symbol: 'USD'
            }
        },
        {
            id: 2,
            liFrom: {
                companyName: 'IRS'
            },
            liDate: '21 Aug 2020',
            liTotal: 1500,
            currencyType: {
                symbol: 'USD'
            }
        },
        {
            id: 3,
            liFrom: {
                companyName: 'Nest'
            },
            liDate: '21 Aug 2020',
            liTotal: -200,
            currencyType: {
                symbol: 'HKD'
            }
        },
        {
            id: 4,
            liFrom: {
                companyName: 'IRS'
            },
            liDate: '21 Aug 2020',
            liTotal: -800,
            currencyType: {
                symbol: 'HKD'
            }
        },
        {
            id: 5,
            liFrom: {
                companyName: 'NYC Coworking'
            },
            liDate: '21 Aug 2020',
            liTotal: 7600,
            currencyType: {
                symbol: 'USD'
            }
        },
        {
            id: 6,
            liFrom: {
                companyName: 'Nest'
            },
            liDate: '21 Aug 2020',
            liTotal: -200,
            currencyType: {
                symbol: 'HKD'
            }
        }
    ];


    ngOnInit() {
        console.log('accounts');
    }
    viewLedgerItem(ledgerItemId: number, ledgerItemTotal: number): void {
        if (ledgerItemTotal < 0) {
            console.log(`view bill: ${ledgerItemId}`);
            this.router.navigate(['/ap', ledgerItemId]);
        } else {
            console.log(`view invoice: ${ledgerItemId}`);
            this.router.navigate(['/ar', ledgerItemId]);
        }
    }
}
