import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Config } from './../config/config';

import { IBroadcastMessage } from './../model/core/broadcast-message.interface';

import { ActionResponse } from './../model/service/action-response';

@Injectable()
export class ApiService {
    private readonly broadcastMessageSubject: Subject<IBroadcastMessage> = new Subject<IBroadcastMessage>();

    headers: HttpHeaders | undefined;

    constructor(
        private readonly router: Router,
        private readonly http: HttpClient
        ) {
        this.setHeaders();
    }
    logConsole(message: string): void {
        console.log(`Api Message: ${message}`);
    }
    setHeaders(): void {
        this.headers = new HttpHeaders();
        this.headers.append('Content-Type', 'application/json');
    }
    callAPI(actionName: string, requestData: any = {}): Observable<ActionResponse> {
        const body: any = requestData;
        // no need to check if cloud or local, set the same
        const actionUrl = `${Config.ApiUrl}${actionName}`;
        const options: any = {
            headers: this.headers,
            responseType: 'json',
        };
       return this.http.post(actionUrl, body, options)
            .pipe(
                catchError(this.handleControllerError(body))
            );
    }
    handleControllerError(error: any): any {
        console.log('api error');
        console.log(error);
        throwError(error);
    }
    // broadcasting
    public sendBroadcastMessage(message: string, data: any = null): void {
        const broadcast: IBroadcastMessage = { message, data };
        this.setBroadcastMessage(broadcast);
    }
    public setBroadcastMessage(broadcast: IBroadcastMessage): void {
        this.broadcastMessageSubject.next(broadcast);
    }
    public listenForBroadcastMessage(): Observable<IBroadcastMessage> {
        return this.broadcastMessageSubject.asObservable();
    }
    navigateTo(page: string, itemId: number): void {
        console.log(`navigate to : ${page} : ${itemId}`);
        if (itemId > 0) {
            this.router.navigate([page, itemId]);
        } else {
            this.router.navigate([page]);
        }
        /*
        .then(() => {
          window.location.reload();
        });
        */
    }

    /* General Utilities */
    public findObjectInArrayByKey(checkObject: Array<any>, key: string, value: any): any {
        for (const check of checkObject) {
            if (check[key] === value) {
                return check;
            }
        }
        return null;
    }
    public isEmpty(checkObject: any): boolean {
        if (checkObject === undefined) {
            return true;
        }
        if (checkObject === null) {
            return true;
        }
        if (typeof checkObject === 'string') {
            if (checkObject === '') {
                return true;
            }
        }
        return false;
    }
}
