<main>
    <div class="uk-container">
        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
            <h1 class="uk-heading-small uk-text-center">Customers</h1>
            <div *ngFor="let customer of customers"
                (click)="viewCustomer(customer.id)"
                class="uk-card uk-card-default uk-padding-small">

                <div class="uk-width-1-1">
                    <div>
                        <span class="uk-text-bold" [innerText]="customer.companyName"></span>
                    </div>
                </div>
                <div class="uk-child-width-1-3 uk-flex uk-flex-between uk-flex-middle">
                    <div>{{customer.primaryContact.contactName}}</div>
                    <div>
                        {{customer.primaryContact.phoneNumbers[0].country.phoneCode}}
                        {{customer.primaryContact.phoneNumbers[0].number}}
                    </div>
                    <div>
                        <a [href]="'mailto:' + customer.primaryContact.emailAddresses[0].address">
                            {{customer.primaryContact.emailAddresses[0].address}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <nav class="uk-navbar uk-navbar-container">
        <div class="uk-navbar-center uk-margin-bottom uk-margin-top">
            <a (click)="api.navigateTo('/customer/add', 0)" class="uk-button uk-button-primary">Add Customer</a>
        </div>
    </nav>
</footer>