import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { ApiService } from './../../../service/api.service';

@Component({
    selector: 'bill',
    templateUrl: 'bill.component.html',
})
export class BillComponent implements OnInit {

    constructor(
        private readonly router: Router,
        public readonly api: ApiService
    ) { }


    public bills = [
        {
            id: 3,
            billFrom: {
                companyName: 'Nest'
            },
            billDate: '21 Aug 2020',
            billTotal: -200,
            currencyType: {
                symbol: 'HKD'
            }
        },
        {
            id: 4,
            billFrom: {
                companyName: 'IRS'
            },
            billDate: '21 Aug 2020',
            billTotal: -800,
            currencyType: {
                symbol: 'HKD'
            }
        },
        {
            id: 6,
            billFrom: {
                companyName: 'Nest'
            },
            billDate: '21 Aug 2020',
            billTotal: -200,
            currencyType: {
                symbol: 'HKD'
            }
        }
    ];

    ngOnInit() {
        console.log('bill');
    }
    viewBill(billId: number): void {
        console.log(`view bill: ${billId}`);
        this.router.navigate(['/ap', billId]);
        /*
        .then(() => {
          window.location.reload();
        });
        */
    }
}
