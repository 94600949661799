<main>
    <div class="uk-container">
        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
            <div class="uk-card uk-card-primary uk-padding-small uk-text-center">
                <span class="uk-text-bold">{{invoice.invoiceFrom.companyName}}</span><br />
                <h3 class="uk-text-bold uk-margin-remove">{{invoice.currencyType.symbol}} {{invoice.invoiceTotal | number}}</h3>
                <span class="uk-text-small" [innerText]="invoice.invoiceDate"></span>
            </div>
            <div class="uk-container">
                <div *ngFor="let lineItem of invoice.lineItems"
                    class="uk-card uk-card-default uk-flex uk-flex-middle uk-flex-between uk-padding-small">

                    <div class="uk-flex uk-flex-left uk-flex-middle">
                        <div class="uk-flex uk-flex-left uk-flex-middle">
                            <div class="uk-text-small uk-margin-right" [innerText]="lineItem.itemCount"></div>
                            <div class="uk-text-small" [innerText]="lineItem.itemDescription"></div>
                        </div>
                    </div>
                    <div class="uk-text-small">
                        {{lineItem.currencyType.symbol}} {{(lineItem.itemPrice * lineItem.itemCount) | number}}
                    </div>
                </div>   
            </div>
            <div class="uk-card uk-card-default uk-padding-small uk-text-center">
                <span class="uk-text-bold">{{invoice.invoiceFrom.primaryContact.contactName}}</span>
            </div>  
        </div>
    </div>
    </main>

    <footer>
        <nav class="uk-navbar uk-navbar-container">
            <div class="uk-navbar-center uk-margin-bottom uk-margin-top">
                <a (click)="api.navigateTo('/ar',0)" class="uk-button uk-button-primary">Back to Invoices</a>
            </div>
        </nav>
    </footer>