import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { ApiService } from './service/api.service';

import { AppComponent } from './app.component';


import { LandingComponent } from './views/landing/landing.component';

import { BalanceSheetComponent } from './views/accounting/balance-sheet/balance-sheet.component'
import { BalanceSheetDetailComponent } from './views/accounting/balance-sheet/balance-sheet-detail.component'

import { ForecastComponent } from './views/accounting/forecast/forecast.component';
import { ForecastDetailComponent } from './views/accounting/forecast/forecast-detail.component';

import { AccountComponent } from './views/accounting/general-ledger/account.component';
import { AccountDetailComponent } from './views/accounting/general-ledger/account-detail.component';

import { ProfitLossComponent } from './views/accounting/profit-loss/profit-loss.component';
import { ProfitLossDetailComponent } from './views/accounting/profit-loss/profit-loss-detail.component';

import { BillComponent } from './views/business/payable/bill.component';
import { BillDetailComponent } from './views/business/payable/bill-detail.component';
import { BillCreateComponent } from './views/business/payable/bill-create.component';

import { InvoiceComponent } from './views/business/receivable/invoice.component';
import { InvoiceDetailComponent } from './views/business/receivable/invoice-detail.component';
import { InvoiceCreateComponent } from './views/business/receivable/invoice-create.component';

import { CustomerComponent } from './views/contact/customer/customer.component';
import { CustomerDetailComponent } from './views/contact/customer/customer-detail.component';
import { CustomerAddComponent } from './views/contact/customer/customer-add.component';

import { SupplierComponent } from './views/contact/supplier/supplier.component';
import { SupplierDetailComponent } from './views/contact/supplier/supplier-detail.component';
import { SupplierAddComponent } from './views/contact/supplier/supplier-add.component';

import { MessageComponent } from './views/message/message.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule
  ],
  declarations: [
    AppComponent,

    AccountComponent,
    LandingComponent,
    BalanceSheetComponent,
    BalanceSheetDetailComponent,
    ForecastComponent,
    ForecastDetailComponent,
    AccountComponent,
    AccountDetailComponent,
    ProfitLossComponent,
    ProfitLossDetailComponent,
    BillComponent,
    BillDetailComponent,
    BillCreateComponent,
    InvoiceComponent,
    InvoiceDetailComponent,
    InvoiceCreateComponent,
    CustomerComponent,
    CustomerDetailComponent,
    CustomerAddComponent,
    SupplierComponent,
    SupplierDetailComponent,
    SupplierAddComponent,
    MessageComponent
  ],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
