<main>
    <div class="uk-container">
        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
            <h1 class="uk-heading-small uk-text-center">General Ledger</h1>
            <div class="uk-card uk-card-default uk-padding-small uk-align-center">
                <ul class="uk-subnav uk-subnav-pill uk-flex uk-flex-center" uk-switcher>
                    <li><a href="#">Day</a></li>
                    <li><a href="#">Week</a></li>
                    <li class="uk-active"><a href="#">Month</a></li>
                    <li><a href="#">Year</a></li>
                </ul>
                <ul class="uk-subnav uk-flex uk-flex-center" uk-switcher>
                    <li><a href="#"><span uk-icon="chevron-left"></span></a></li>
                    <li><a href="#" class="uk-active">August 2020</a></li>
                    <li><a href="#"><span uk-icon="chevron-right"></span></a></li>
                </ul>
            </div>
            <div class="uk-card uk-card-primary uk-padding-small">
                <h3>Aug 2020</h3>
            </div>
            <div *ngFor="let ledgerItem of ledgerItems"
                (click)="viewLedgerItem(ledgerItem.id, ledgerItem.liTotal)"
                class="uk-card uk-card-default uk-flex uk-flex-middle uk-flex-between uk-padding-small">

                <div class="uk-width-1-2 uk-flex uk-flex-left uk-flex-middle">
                    <ng-template [ngIf]="ledgerItem.liTotal >= 0">
                        <div class="uk-text-success" uk-icon="icon: triangle-right; ratio: 2"></div>
                    </ng-template>
                    <ng-template [ngIf]="ledgerItem.liTotal &lt; 0">
                        <div class="uk-text-danger" uk-icon="icon: triangle-left; ratio: 2"></div>
                    </ng-template>
                    <div>
                        <span class="uk-text-bold" [innerText]="ledgerItem.liFrom.companyName"></span><br />
                        <span class="uk-text-small" [innerText]="ledgerItem.liDate"></span>
                    </div>
                </div>
                <h4 class="uk-margin-remove uk-text-bold">{{ledgerItem.currencyType.symbol}} {{ledgerItem.liTotal | number}}</h4>
            </div>        
        </div>
    </div>
</main>