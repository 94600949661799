import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './../../../service/api.service';

@Component({
    selector: 'forecast',
    templateUrl: 'forecast.component.html',
})
export class ForecastComponent implements OnInit {

    constructor(
        private readonly router: Router,
        public readonly api: ApiService
    ) { }

    public forecastSheet = {
        companyName: 'The Dino Zoo',
        currencyType: 'HKD',
        timespans: ['Jan', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028'],
        forecasts: [
            {
                group: 'Revenue',
                lineItems: [
                    {
                        itemName: 'Advertisement',
                        projections: [0, 4340000, 8580000, 21700000, 65100000, 195300000, 781200000, 1953000000, 3906000000]
                    },
                    {
                        itemName: 'Paid Subscriptions',
                        projections: [0, 1545000, 3090000, 7725000, 23175000, 75318750, 301275000, 753187500, 7531875000]
                    },
                    {
                        itemName: 'Licensing',
                        projections: [0, 200000, 400000, 600000, 800000, 1000000, 1200000, 1400000, 1600000]
                    }
                ],
                totals: [0, 6085000, 12170000, 30025000, 89075000, 271618750, 1083675000, 2707587500, 1143975000]
            },
            {
                group: 'COGS',
                lineItems: [
                    {
                        itemName: 'COGS Digital',
                        projections: [23590, 218480, 229356, 240774, 252762, 265348, 214747, 225429, 236643]
                    },
                    {
                        itemName: 'COGS Other',
                        projections: [700, 127090, 130290, 200390, 347462, 639006, 1219296, 2376841, 4689148]
                    }
                ],
                totals: [24290, 345570, 359646, 441134, 600225, 904353, 1434043, 2602369, 4925791]
            },
            {
                group: 'SG&A',
                lineItems: [
                    {
                        itemName: 'Marketing',
                        projections: [0, 494000, 1810000, 8666000, 17332000, 34664000, 69328000, 138656000, 277315000]
                    },
                    {
                        itemName: 'Overheads',
                        projections: [0, 8701000, 18530030, 24133131, 28003315, 29597703, 31290729, 33089495, 35001682]
                    }
                ],
                totals: [0, 9195000, 20340030, 32799131, 45335315, 64261703, 100618729, 171745495, 312313682]

            }
        ]
    };

    

    ngOnInit() {
        console.log('forecast');
    }
}
