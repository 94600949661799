import { Component, OnInit } from '@angular/core';

import { ApiService } from './../../../service/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'supplier',
    templateUrl: 'supplier.component.html',
})
export class SupplierComponent implements OnInit {

    constructor(
        private readonly router: Router,
        public readonly api: ApiService
    ) { }


    public suppliers = [
        {
            id: 1,
            companyName: 'Microsoft',
            primaryContact: {
                contactName: 'Bill Gates',
                phoneNumbers: [
                    {
                        number: '888 888 9999',
                        country: {
                            phoneCode: '+1'
                        }
                    }
                ],
                emailAddresses: [
                    {
                        address: 'gates@microsoft.com'
                    }
                ]
            }
        },
        {
            id: 2,
            companyName: 'Kelloggs',
            primaryContact: {
                contactName: 'Jane Doe',
                phoneNumbers: [
                    {
                        number: '888 888 8888',
                        country: {
                            phoneCode: '+1'
                        }
                    }
                ],
                emailAddresses: [
                    {
                        address: 'j.doe@kelloggs.com'
                    }
                ]
            }
        }
    ];


    ngOnInit() {
        console.log('supplier');
    }
    viewSupplier(supplierId: number): void {
        console.log(`view supplier: ${supplierId}`);
        this.router.navigate(['/supplier', supplierId]);
    }
}
