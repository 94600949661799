<header>
  <nav class="uk-navbar uk-navbar-container uk-margin">
    <div class="uk-navbar-left">
      <a href="#offcanvas-menu" class="uk-navbar-toggle" uk-navbar-toggle-icon uk-toggle></a>
    </div>

    <div class="uk-navbar-right">
      <div>
          <a class="uk-icon-button uk-button-secondary uk-margin-right" uk-search-icon href="#"></a>
          <div class="uk-drop" uk-drop="mode: click; pos: left-center; offset: 0">
              <form class="uk-search uk-search-navbar uk-width-1-1">
                  <input class="uk-search-input" type="search" placeholder="Search..." autofocus>
              </form>
          </div>
      </div>  
    </div>

    <div id="offcanvas-menu" uk-offcanvas>
        <div class="uk-offcanvas-bar">
            <ul class="uk-nav uk-nav-default">
                <li class="uk-nav-header"><span class="uk-margin-right" uk-icon="file-text"></span>Business</li>
                <li><a routerLinkActive="uk-active" routerLink="/ar">Invoices</a></li>
                <li><a routerLinkActive="uk-active" routerLink="/ap">Bills To Pay</a></li>
                <li class="uk-nav-divider"></li>
                
                <li class="uk-nav-header"><span class="uk-margin-right" uk-icon="list"></span>Accounting</li>
                <li><a routerLinkActive="uk-active" routerLink="/ledger">General Ledger</a></li>
                <li><a routerLinkActive="uk-active" routerLink="/account">Balance Sheet</a></li>
                <li><a routerLinkActive="uk-active" routerLink="/pl">Profit &amp; Loss</a></li>
                <li><a routerLinkActive="uk-active" routerLink="/forecast">Forecast</a></li>
                <li class="uk-nav-divider"></li>

                <li class="uk-nav-header"><span class="uk-margin-right" uk-icon="users"></span>Contacts</li>
                <li><a routerLinkActive="uk-active" routerLink="/customer">Customers</a></li>
                <li><a routerLinkActive="uk-active" routerLink="/supplier">Suppliers</a></li>
                <!--
                <li>
                  <a routerLinkActive="uk-active" routerLink="#"><span class="uk-margin-right" uk-icon="camera"></span>Capture Invoices</a>
                </li>
                <li>
                  <a routerLinkActive="uk-active" routerLink="/message"><span class="uk-margin-right" uk-icon="comments"></span>View Messages</a>
                </li>
              -->
            </ul>
        </div>
    </div>
  </nav>
</header>

<router-outlet></router-outlet>
  <!--

<main>
</main>

<footer>

  <nav class="uk-navbar uk-navbar-container">
    <div class="uk-navbar-center uk-margin-bottom uk-margin-top">
      <a routerLinkActive="uk-active" routerLink="/invoice" class="uk-icon-button uk-button-default uk-margin-right" uk-icon="file-text"></a>
      <a routerLinkActive="uk-active" routerLink="/account" class="uk-icon-button uk-button-default uk-margin-right" uk-icon="list"></a>
      <a routerLinkActive="uk-active" routerLink="#" class="uk-icon-button uk-button-primary" uk-icon="camera"></a>
      <a routerLinkActive="uk-active" routerLink="/message" class="uk-icon-button uk-button-default uk-margin-left" uk-icon="comments"></a>
      <a routerLinkActive="uk-active" routerLink="#" class="uk-icon-button uk-button-default uk-margin-left" uk-icon="more"></a>
    </div>
  </nav>
</footer>
-->