<main>
    <div class="uk-container">
        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
            <h1 class="uk-heading-small uk-text-center">Balance Sheet</h1>

            <div class="uk-card uk-card-default uk-padding-small uk-text-center uk-overflow-auto">
                <table class="uk-table uk-table-divider uk-table-small table-report table-balance">
                <thead>
                    <tr class="report-header">
                        <th colspan="3">
                            Lalamove Automotive (Thailand) Limited<br />
                            As of 31 December 2020
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="report-subheader">
                        <td></td>
                        <td>31 Dec 2020</td>
                        <td>31 Dec 2019</td>
                    </tr>

                    <tr class="report-group">
                        <td colspan="3" class="report-header">Assets</td>
                    </tr>
                    <tr class="report-section">
                        <td colspan="3" class="report-header">Bank</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">KBank CA #063-8-61881-8</td><td>5,000</td><td>-</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">KBank SA #063-8-65851-8</td><td>266,645</td><td>-</td>
                    </tr>
                    <tr class="report-section-total">
                        <td class="report-header">Total Bank</td><td>271,345</td><td>-</td>
                    </tr>
                    
                    <tr class="report-section">
                        <td colspan="3" class="report-header">Current Assets</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">LalaMove EasyVan Thailand Limited (Thailand)</td><td>(4,466,454)</td><td>-</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Other deposits</td><td>207,040</td><td>-</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Tax recoverable</td><td>2,168</td><td>-</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Temp AR</td><td>2,620,300</td><td>-</td>
                    </tr>
                    <tr class="report-section-total">
                        <td class="report-header">Total Current Assets</td><td>(1,636,945)</td><td>-</td>
                    </tr>

                    <tr class="report-group-total">
                        <td class="report-header">Total Assets</td><td>(1,365,601)</td><td>-</td>
                    </tr>


                    
                    <tr class="report-group">
                        <td colspan="3" class="report-header">Liabilities</td>
                    </tr>
                    <tr class="report-section">
                        <td colspan="3" class="report-header">Current Liabilities</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Temp AP</td><td>5,000</td><td>-</td>
                    </tr>
                    <tr class="report-section-total">
                        <td class="report-header">Total Current Liabilities</td><td>5,000</td><td>-</td>
                    </tr>
                    
                    <tr class="report-section">
                        <td colspan="3" class="report-header">Non-Current Liabilities</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Accruals</td><td>-</td><td>(12,000)</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Tax payables - PND 3</td><td>79</td><td>-</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Tax payables - PND 53</td><td>8,372</td><td>-</td>
                    </tr>
                    <tr class="report-section-total">
                        <td class="report-header">Total Non-Current Liabilities</td><td>8,451</td><td>(12,000)</td>
                    </tr>

                    <tr class="report-group-total">
                        <td class="report-header">Total Liabilities</td><td>14,251</td><td>(12,000)</td>
                    </tr>


                    <tr class="report-spacer"><td></td></tr>
                    <tr class="report-total">
                        <td class="report-header">Net Assets</td><td>(1,379,852)</td><td>(12,000)</td>
                    </tr>

                    
                    
                    <tr class="report-group">
                        <td colspan="3" class="report-header">Equity</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Current Year Earnings</td><td>(1,367,852)</td><td>(12,000)</td>
                    </tr>
                    <tr class="report-item">
                        <td class="report-header">Retained Earnings (960)</td><td>(12,000)</td><td>-</td>
                    </tr>
                    <tr class="report-group-total">
                        <td class="report-header">Total Equity</td><td>(1,379,852)</td><td>(12,000)</td>
                    </tr>

                 </tbody>
                </table>
            </div>
        </div>
    </div>
</main>