import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './../../../service/api.service';

@Component({
    selector: 'customer',
    templateUrl: 'customer.component.html',
})
export class CustomerComponent implements OnInit {

    constructor(
        private readonly router: Router,
        public readonly api: ApiService
    ) { }


    public customers = [
        {
            id: 1,
            companyName: 'HSBC',
            primaryContact: {
                contactName: 'Jake Lai',
                phoneNumbers: [
                    {
                        number: '8888 8888',
                        country: {
                            phoneCode: '+852'
                        }
                    }
                ],
                emailAddresses: [
                    {
                        address: 'jake.lai@hsbc.com'
                    }
                ]
            }
        },
        {
            id: 2,
            companyName: 'Food Panda',
            primaryContact: {
                contactName: 'Carol Wong',
                phoneNumbers: [
                    {
                        number: '8888 9999',
                        country: {
                            phoneCode: '+852'
                        }
                    }
                ],
                emailAddresses: [
                    {
                        address: 'carol.wong@foodpanda.com'
                    }
                ]
            }
        }
    ];

    ngOnInit() {
        console.log('customer');
    }
    viewCustomer(customerId: number): void {
        console.log(`view customer: ${customerId}`);
        this.router.navigate(['/customer', customerId]);
    }
}
