import { Component, OnInit } from '@angular/core';
import { Config } from './config/config';
import { ApiService } from './service/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'webui';
  
  constructor(
      public api: ApiService) {
    }
    ngOnInit() {
      console.log('initialized');
      this.api.logConsole('talk to service');
    }
}
