import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from '../../../service/api.service';

@Component({
    selector: 'customer-add',
    templateUrl: 'customer-add.component.html',
})
export class CustomerAddComponent implements OnInit {

    constructor(
        private readonly router: Router,
        public readonly api: ApiService
    ) { }


    ngOnInit() {
        console.log('customer add');
    }
}
