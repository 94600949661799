<main>
    <div class="uk-container">
        <div class="uk-grid-small uk-child-width-1-1" uk-grid>
            <h1 class="uk-heading-small uk-text-center">Upload Bill</h1>
            <div class="uk-card uk-card-default uk-padding-small uk-align-center">

                <div class="js-upload uk-placeholder uk-text-center">
                    <span uk-icon="icon: cloud-upload"></span>
                    <div uk-form-custom>
                        <input type="file" multiple>
                        <span class="uk-link">Choose a file</span>
                    </div>
                    <span class="uk-text-middle">or drag it here.</span>
                </div>
                
                <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
            </div>
            <div class="uk-card uk-card-default uk-padding-small uk-flex uk-flex-center">
                <a (click)="api.navigateTo('/ap',0)" class="uk-button uk-button-primary">Upload</a>
            </div>
        </div>
    </div>
</main>

<footer>
    <nav class="uk-navbar uk-navbar-container">
        <div class="uk-navbar-center uk-margin-bottom uk-margin-top">
            <a (click)="api.navigateTo('/ap',0)" class="uk-button uk-button-primary">Back to Bills</a>
        </div>
    </nav>
</footer>


<script>
    var bar = document.getElementById('js-progressbar');
    UIkit.upload('.js-upload', {
        url: '',
        multiple: true,
        beforeSend: function () {
            console.log('beforeSend', arguments);
        },
        beforeAll: function () {
            console.log('beforeAll', arguments);
        },
        load: function () {
            console.log('load', arguments);
        },
        error: function () {
            console.log('error', arguments);
        },
        complete: function () {
            console.log('complete', arguments);
        },

        loadStart: function (e) {
            console.log('loadStart', arguments);

            bar.removeAttribute('hidden');
            bar.max = e.total;
            bar.value = e.loaded;
        },
        progress: function (e) {
            console.log('progress', arguments);

            bar.max = e.total;
            bar.value = e.loaded;
        },
        loadEnd: function (e) {
            console.log('loadEnd', arguments);

            bar.max = e.total;
            bar.value = e.loaded;
        },
        completeAll: function () {
            console.log('completeAll', arguments);

            setTimeout(function () {
                bar.setAttribute('hidden', 'hidden');
            }, 1000);

            alert('Upload Completed');
        }
    });
</script>