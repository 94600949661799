import { Component, OnInit } from '@angular/core';

import { ApiService } from './../../../service/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'invoice-detail',
    templateUrl: 'invoice-detail.component.html',
})
export class InvoiceDetailComponent implements OnInit {

    constructor(
        private readonly router: Router,
        public readonly api: ApiService
    ) { }

    invoice = {
        id: 1,
        invoiceFrom: {
            companyName: 'Tokkea Co., Ltd.',
            primaryContact: {
                contactName: 'Moon IT Freelance'
            }
        },
        invoiceDate: '21 Aug 2020',
        invoiceTotal: 8000,
        currencyType: {
            symbol: 'USD'
        },
        lineItems: [
            {
                itemCount: 10,
                itemDescription: 'Dell mouse',
                currencyType: {
                    symbol: 'USD'
                },
                itemPrice: 100
            },
            {
                itemCount: 6,
                itemDescription: 'Dell computer',
                currencyType: {
                    symbol: 'USD'
                },
                itemPrice: 1000
            },
            {
                itemCount: 10,
                itemDescription: 'Dell hard drive',
                currencyType: {
                    symbol: 'USD'
                },
                itemPrice: 100
            }
        ]
    };
    
    ngOnInit() {
        console.log('invoice detail');
    }
}
