import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { ApiService } from './../../service/api.service';

@Component({
    selector: 'message',
    templateUrl: 'message.component.html',
})
export class MessageComponent implements OnInit {

    constructor(
        private readonly router: Router,
        private readonly api: ApiService
    ) { }


    ngOnInit() {
        console.log('messages');
    }
}
